<template>
  <div class="not-found">
    <p class="not-found__code">404</p>
    <p class="not-found__message">No pudimos encontrar lo que buscabas</p>

    <ul class="not-found__list">
      <li class="not-found__list__social">
        <a href="https://bit.ly/3M8lgP3">/facebook</a>
      </li>
      <li class="not-found__list__social">
        <a href="https://bit.ly/3awalS9">/instagram</a>
      </li>
      <li class="not-found__list__social">
        <a href="https://bit.ly/3aI1vRx">/whatsapp</a>
      </li>
      <li class="not-found__list__social">
        <a href="https://www.dimedicalcorporativo.mx/"
          >/dimedicalcorporativo.mx</a
        >
      </li>
    </ul>
  </div>
</template>
